<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#widgetable-wrapper {
  width: 100vw;
  min-height: 100vh;
  color: #fff;
  box-sizing: border-box;
}

@media screen and (max-width: 900px) {
  #widgetable-wrapper {
    background: url('./assets/bg-h5.jpeg') no-repeat center/cover;
    padding-top: 2em;
  }
}

@media screen and (orientation: landscape) and (min-width: 900px) {
  #widgetable-wrapper {
    min-width: 980px;
    background: url('./assets/bg-pc.jpeg') no-repeat center/cover;
    position: relative;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>