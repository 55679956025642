<template>
  <header>
    <img class="logo" src="../assets/popwatch.png" alt="PopWatch" />
    <h1 class="slogan">PopWatch makes your feeling adorable.</h1>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
header {
  text-align: center;
}

.logo {
  margin: auto;
  width: 55%;
  max-width: 260px;
}

.slogan {
  width: 90%;
  margin: 1.2rem auto 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
}

@media screen and (orientation: landscape) and (min-width: 900px) {
  header {
    text-align: left;
  }

  .logo {
    width: 260px;
    position: absolute;
    top: 16%;
    left: 90px;
  }

  .slogan {
    width: 45%;
    line-height: 1.3;
    font-size: 60px; /* 进一步增大字体大小 */
    position: absolute;
    top: 45%;
    left: 90px;
    text-align: left; /* 确保左对齐 */
  }
}
</style>