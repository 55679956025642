<template>
  <footer>
    <div class="contact-email">
      Email: <a :href="`mailto:${email}`">{{ email }}</a>
    </div>
    <div>Copyright © 2022 Save My Day LLC.</div>
    <div class="link-wrapper">
      <a class="link-policy" :href="privacyPolicyLink">Privacy Policy</a>
      <span class="split">|</span>
      <a class="link-terms" :href="termsLink">Terms of Service</a>
      <span class="split">|</span>
      <router-link class="link-faq" to="/faq">FAQ</router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      email: '',
      privacyPolicyLink: '',
      termsLink: ''
    }
  },
  mounted() {
    const domain = window.location.hostname;
    if (domain === 'popwatchapp.cn') {
      this.email = 'service@popwatchapp.cn';
      this.privacyPolicyLink = '/cn/privacy.html';
      this.termsLink = '/cn/terms.html';
    } else {
      this.email = 'service@popwatchapp.com';
      this.privacyPolicyLink = '/privacy.html';
      this.termsLink = '/terms.html';
    }
  }
}
</script>

<style scoped>
footer {
  color: rgba(255, 255, 255, 0.45);
  text-align: center;
  line-height: 2em;
  margin: auto;
  font-size: 0.8rem;
}

footer a {
  color: rgba(255, 255, 255, 0.45);
  text-decoration: underline;
}

.contact-email {
  /* Add any specific styles you want for this element */
}

.link-faq {
  color: rgba(255, 255, 255, 0.45);
  text-decoration: underline;
}

@media screen and (orientation: landscape) and (min-width: 900px) {
  footer {
    font-size: 0.82rem;
    position: absolute;
    bottom: 1%;
    left: 0;
    right: 0;
  }

  .link-wrapper {
    display: inline-block;
    margin-left: 4em;
  }

  .link-wrapper .split {
    margin: 0 1em;
    visibility: visible;
  }
}
</style>