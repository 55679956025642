<template>
  <div id="widgetable-wrapper">
    <Header />
    <main>
      <ExampleImage />
      <DownloadButtons />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import DownloadButtons from '../components/DownloadButtons.vue'
import ExampleImage from '../components/ExampleImage.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    DownloadButtons,
    ExampleImage,
    Footer
  }
}
</script> 