import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import FAQ from '../components/FAQ.vue'
import FAQDetail from '../components/FAQDetail.vue'
import FAQDetail2 from '../components/FAQDetail2.vue'
import FAQDetail3 from '../components/FAQDetail3.vue'
import FAQDetail4 from '../components/FAQDetail4.vue'
import FAQDetail6 from '../components/FAQDetail6.vue'
import FAQDetail5 from '../components/FAQDetail5.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/faq/complications-not-updating',
    name: 'ComplicationsNotUpdating',
    component: FAQDetail
  },
  {
    path: '/faq/cannot-add-complications',
    name: 'CannotAddComplications',
    component: FAQDetail2
  },
  {
    path: '/faq/display-issues',
    name: 'DisplayIssues',
    component: FAQDetail3
  },
  {
    path: '/faq/animation-not-working',
    name: 'AnimationNotWorking',
    component: FAQDetail4
  },
  {
    path: '/faq/face-unavailable',
    name: 'FaceUnavailable',
    component: FAQDetail5
  },
  {
    path: '/faq/widget-not-working',
    name: 'WidgetNotWorking',
    component: FAQDetail6
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router 