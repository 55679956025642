<template>
  <div class="faq-container">
    <h1 class="faq-title">Possible Watch Faces problems</h1>
    <div class="question-icon">
      <svg width="80" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" stroke-width="2"/>
        <path d="M12 17H12.01" stroke="white" stroke-width="2" stroke-linecap="round"/>
        <path d="M9.09009 9.00008C9.32519 8.33175 9.78924 7.76819 10.4 7.40921C11.0108 7.05024 11.729 6.91902 12.4273 7.03879C13.1255 7.15857 13.7589 7.52161 14.2151 8.06361C14.6713 8.60561 14.9211 9.29207 14.9201 10.0001C14.9201 12.0001 11.9201 13.0001 11.9201 13.0001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="faq-list">
      <button 
        v-for="(question, index) in questions" 
        :key="index"
        class="faq-item"
        @click="handleQuestionClick(question)"
      >
        {{ question }}
      </button>
    </div>
    <button class="close-button" @click="goBack" aria-label="Close">
      <span class="close-icon">×</span>
    </button>
  </div>
</template>

<script>
const FAQ_ITEMS = {
  COMPLICATION_UPDATE: {
    text: 'Apple Watch complications data not updating',
    route: '/faq/complications-not-updating'
  },
  COMPLICATION_ADD: {
    text: 'Cannot add complications to my Apple Watch',
    route: '/faq/cannot-add-complications'
  },
  DISPLAY_ISSUE: {
    text: 'Displaying only white lines or blank space instead of complications (widgets)',
    route: '/faq/display-issues'
  },
  ANIMATION: {
    text: 'Animated Watch Faces not working',
    route: '/faq/animation-not-working'
  },
  UNAVAILABLE: {
    text: 'Error: Watch Face Unavailable',
    route: '/faq/face-unavailable'
  },
  WIDGET_ISSUE: {
    text: 'One of the widgets is not working',
    route: '/faq/widget-not-working'
  }
}

export default {
  name: 'FAQ',
  data() {
    return {
      questions: Object.values(FAQ_ITEMS).map(item => item.text)
    }
  },
  methods: {
    handleQuestionClick(question) {
      const faqItem = Object.values(FAQ_ITEMS).find(item => item.text === question)
      if (faqItem) {
        this.$router.push(faqItem.route)
      } else if (question === 'Error: Watch Face Unavailable') {
        this.$router.push('/faq/detail5')
      } else {
        console.warn('Question route not found:', question)
      }
    },
    goBack() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.faq-container {
  min-height: 100vh;
  background-color: #1c1c1e;
  color: white;
  padding: 2rem;
  position: relative;
}

.faq-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 600;
}

.question-icon {
  text-align: center;
  margin-bottom: 2rem;
}

.faq-list {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  color: white;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.faq-item:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.close-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
  .faq-title {
    font-size: 2rem;
  }

  .faq-container {
    padding: 1rem;
  }

  .question-icon svg {
    width: 60px;
    height: 60px;
  }
}
</style> 