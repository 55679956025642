<template>
  <div class="faq-detail-container">
    <div class="content-wrapper">
      <h1 class="title">Displaying only white lines or blank space instead of complications (widgets)</h1>

      <div class="watch-examples">
        <div class="watch-frame">
          <div class="watch-screen">
            <div class="watch-time">3:36</div>
            <div class="watch-date">THU 1</div>
            <div class="watch-name">PopWatch</div>
            <div class="white-lines">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="bottom-lines">
                <span>--</span>
                <span>--</span>
                <span>--</span>
              </div>
            </div>
          </div>
        </div>
        <div class="watch-frame">
          <div class="watch-screen">
            <div class="watch-time">3:36</div>
            <div class="watch-date">THU 1</div>
          </div>
        </div>
      </div>

      <div class="instructions">
        <p class="main-text">
          White sticks or blank space are displayed during the installation of Complications on your Apple Watch. Sometimes it can take <span class="highlight">up to 10 minutes</span>.
        </p>

        <p class="instruction-text">
          Just wait a bit and the white sticks will be replaced with the correct Complication elements.
        </p>

        <p class="warning-text">
          If the white sticks don't disappear, try reinstalling Complication and make sure your <span class="highlight">Apple Watch battery is over 10%</span>.
        </p>
      </div>

      <div class="button-group">
        <button class="back-button" @click="goBack">Back</button>
        <button class="ok-button" @click="goBack">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQDetail3',
  methods: {
    goBack() {
      this.$router.push('/faq')
    }
  }
}
</script>

<style scoped>
.faq-detail-container {
  min-height: 100vh;
  background-color: #1c1c1e;
  color: white;
  padding: 2rem;
  position: relative;
}

.content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
}

.watch-examples {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 3rem 0;
}

.watch-frame {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 40px;
  padding: 8px;
  position: relative;
}

.watch-screen {
  width: 180px;
  height: 220px;
  background: #000;
  border-radius: 35px;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.watch-time {
  font-size: 2.5rem;
  font-weight: 300;
  text-align: center;
  margin-top: 10px;
}

.watch-date {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #ff3b30;
  font-size: 0.8rem;
}

.watch-name {
  text-align: center;
  margin-top: 10px;
  font-size: 1rem;
}

.white-lines {
  margin-top: 20px;
}

.line {
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  margin: 10px 0;
}

.bottom-lines {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  color: rgba(255, 255, 255, 0.5);
}

.instructions {
  margin: 3rem 0;
  text-align: center;
}

.main-text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.instruction-text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.warning-text {
  font-size: 1.2rem;
  line-height: 1.6;
}

.highlight {
  color: #ff9500;
}

.button-group {
  display: flex;
  gap: 1rem;
  margin-top: 3rem;
  justify-content: center;
}

.back-button, .ok-button {
  padding: 0.8rem 2rem;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  min-width: 120px;
}

.back-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.ok-button {
  background: linear-gradient(to right, #ff9500, #ff2d55);
  color: white;
}

@media (max-width: 768px) {
  .faq-detail-container {
    padding: 1rem;
  }

  .title {
    font-size: 1.8rem;
  }

  .watch-examples {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .watch-screen {
    width: 150px;
    height: 180px;
  }

  .main-text, .instruction-text, .warning-text {
    font-size: 1rem;
  }
}
</style> 