<template>
  <div class="faq-detail-container">
    <div class="content-wrapper">
      <h1 class="title">PopWatch needs permissions to access</h1>
      <p class="subtitle">your location for weather and your activity for health data.</p>

      <h2 class="section-title">Please double check on your iPhone:</h2>

      <div class="permission-section">
        <h3 class="permission-title">For weather:</h3>
        <div class="permission-flow">
          <div class="step">
            <div class="icon settings-icon"></div>
            <span>Settings</span>
          </div>
          <div class="arrow">→</div>
          <div class="step">
            <div class="icon privacy-icon"></div>
            <span>Privacy</span>
          </div>
          <div class="arrow">→</div>
          <div class="step">
            <div class="icon location-icon"></div>
            <span>Location Services</span>
          </div>
          <div class="arrow">→</div>
          <div class="step">
            <div class="icon app-icon"></div>
            <span>PopWatch</span>
          </div>
        </div>
      </div>

      <div class="permission-section">
        <h3 class="permission-title">For health:</h3>
        <div class="permission-flow">
          <div class="step">
            <div class="icon settings-icon"></div>
            <span>Settings</span>
          </div>
          <div class="arrow">→</div>
          <div class="step">
            <div class="icon privacy-icon"></div>
            <span>Privacy</span>
          </div>
          <div class="arrow">→</div>
          <div class="step">
            <div class="icon health-icon"></div>
            <span>Health</span>
          </div>
          <div class="arrow">→</div>
          <div class="step">
            <div class="icon app-icon"></div>
            <span>PopWatch</span>
          </div>
        </div>
      </div>

      <div class="instructions">
        <p>Verify PopWatch is allowed to read this data.</p>
        <p>If these settings are not showing under Privacy please turn it on and restart the application.</p>
        <p>Also, please verify you do not have another application blocking the connection. IE: VPN. Try disabling your VPN to test.</p>
        <p>If the above doesn't help, reinstall the app from the Apple Store.</p>
      </div>

      <div class="button-group">
        <button class="back-button" @click="goBack">Back</button>
        <button class="ok-button" @click="goBack">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQDetail',
  methods: {
    goBack() {
      this.$router.push('/faq')
    }
  }
}
</script>

<style scoped>
.faq-detail-container {
  min-height: 100vh;
  background-color: #1c1c1e;
  color: white;
  padding: 2rem;
  position: relative;
}

.content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #ff9500;
}

.section-title {
  font-size: 1.5rem;
  margin: 2rem 0;
}

.permission-section {
  margin: 2rem 0;
}

.permission-title {
  font-size: 1.3rem;
  color: #ff9500;
  margin-bottom: 1rem;
}

.permission-flow {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1.5rem 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.settings-icon {
  background: linear-gradient(145deg, #636366, #48484A);
  position: relative;
}

.settings-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 55%;
  height: 55%;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0001 8.25C9.93508 8.25 8.25008 9.935 8.25008 12C8.25008 14.065 9.93508 15.75 12.0001 15.75C14.0651 15.75 15.7501 14.065 15.7501 12C15.7501 9.935 14.0651 8.25 12.0001 8.25ZM21.5151 12.86L19.7851 12.315C19.6701 11.775 19.4851 11.25 19.2301 10.755L20.0251 9.135C20.1151 8.97 20.0851 8.76 19.9501 8.625L18.3751 7.05C18.2401 6.915 18.0301 6.885 17.8651 6.975L16.2451 7.77C15.7501 7.515 15.2251 7.33 14.6851 7.215L14.1401 5.485C14.0951 5.305 13.9301 5.175 13.7501 5.175H11.6251C11.4451 5.175 11.2801 5.305 11.2351 5.485L10.6901 7.215C10.1501 7.33 9.62508 7.515 9.13008 7.77L7.51008 6.975C7.34508 6.885 7.13508 6.915 7.00008 7.05L5.42508 8.625C5.29008 8.76 5.26008 8.97 5.35008 9.135L6.14508 10.755C5.89008 11.25 5.70508 11.775 5.59008 12.315L3.86008 12.86C3.68008 12.905 3.55008 13.07 3.55008 13.25V15.375C3.55008 15.555 3.68008 15.72 3.86008 15.765L5.59008 16.31C5.70508 16.85 5.89008 17.375 6.14508 17.87L5.35008 19.49C5.26008 19.655 5.29008 19.865 5.42508 20L7.00008 21.575C7.13508 21.71 7.34508 21.74 7.51008 21.65L9.13008 20.855C9.62508 21.11 10.1501 21.295 10.6901 21.41L11.2351 23.14C11.2801 23.32 11.4451 23.45 11.6251 23.45H13.7501C13.9301 23.45 14.0951 23.32 14.1401 23.14L14.6851 21.41C15.2251 21.295 15.7501 21.11 16.2451 20.855L17.8651 21.65C18.0301 21.74 18.2401 21.71 18.3751 21.575L19.9501 20C20.0851 19.865 20.1151 19.655 20.0251 19.49L19.2301 17.87C19.4851 17.375 19.6701 16.85 19.7851 16.31L21.5151 15.765C21.6951 15.72 21.8251 15.555 21.8251 15.375V13.25C21.8251 13.07 21.6951 12.905 21.5151 12.86Z'/%3E%3C/svg%3E");
}

.privacy-icon {
  background: linear-gradient(145deg, #30D158, #27B348);
  position: relative;
}

.privacy-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 55%;
  height: 55%;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2L4 5.4V10.5C4 15.7 7.4 20.6 12 22C16.6 20.6 20 15.7 20 10.5V5.4L12 2ZM18 10.5C18 14.9 15.1 19.1 12 20.4C8.9 19.1 6 14.9 6 10.5V6.6L12 4L18 6.6V10.5Z'/%3E%3Cpath d='M12 12.5C13.1046 12.5 14 11.6046 14 10.5C14 9.39543 13.1046 8.5 12 8.5C10.8954 8.5 10 9.39543 10 10.5C10 11.6046 10.8954 12.5 12 12.5Z'/%3E%3Cpath d='M12 13.5C10.3 13.5 7 14.3 7 16V17H17V16C17 14.3 13.7 13.5 12 13.5Z'/%3E%3C/svg%3E");
}

.location-icon {
  background: linear-gradient(145deg, #0A84FF, #0071E7);
  position: relative;
}

.location-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 55%;
  height: 55%;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2C7.6 2 4 5.6 4 10C4 15.4 11 21.5 11.3 21.8C11.5 21.9 11.7 22 12 22C12.3 22 12.5 21.9 12.7 21.8C13 21.5 20 15.4 20 10C20 5.6 16.4 2 12 2ZM12 18.7C10.1 16.9 6 12.8 6 10C6 6.7 8.7 4 12 4C15.3 4 18 6.7 18 10C18 12.8 13.9 16.9 12 18.7Z'/%3E%3Cpath d='M12 6C9.8 6 8 7.8 8 10C8 12.2 9.8 14 12 14C14.2 14 16 12.2 16 10C16 7.8 14.2 6 12 6ZM12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12Z'/%3E%3C/svg%3E");
}

.health-icon {
  background: linear-gradient(145deg, #FF2D55, #FF0E38);
  position: relative;
}

.health-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 55%;
  height: 55%;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z'/%3E%3C/svg%3E");
}

.app-icon {
  background: linear-gradient(145deg, #FF9F0A, #FF8D00);
  position: relative;
}

.app-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 55%;
  height: 55%;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 4H9V9H4V4ZM10 4H15V9H10V4ZM16 4H20V9H16V4ZM4 10H9V15H4V10ZM10 10H15V15H10V10ZM16 10H20V15H16V10ZM4 16H9V20H4V16ZM10 16H15V20H10V16ZM16 16H20V20H16V16Z'/%3E%3C/svg%3E");
}

.arrow {
  color: #666;
  font-size: 1.5rem;
}

.instructions {
  margin: 2rem 0;
  line-height: 1.6;
}

.instructions p {
  margin: 1rem 0;
  color: rgba(255, 255, 255, 0.8);
}

.button-group {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: center;
}

.back-button, .ok-button {
  padding: 0.8rem 2rem;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  min-width: 120px;
}

.back-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.ok-button {
  background: linear-gradient(to right, #ff9500, #ff2d55);
  color: white;
}

@media (max-width: 768px) {
  .faq-detail-container {
    padding: 1rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .permission-flow {
    gap: 0.5rem;
  }

  .icon {
    width: 40px;
    height: 40px;
  }

  .arrow {
    font-size: 1.2rem;
  }
}
</style> 